var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticStyle: { width: "auto" },
      attrs: {
        absolute: "",
        app: "",
        color: "transparent",
        flat: "",
        height: "75"
      }
    },
    [
      _c(
        "v-btn",
        {
          attrs: { fab: "", small: "" },
          on: {
            click: function($event) {
              _vm.$vuetify.breakpoint.smAndDown
                ? _vm.setDrawer(!_vm.drawer)
                : _vm.$emit("input", !_vm.value)
            }
          }
        },
        [
          _vm.value
            ? _c("v-icon", [_vm._v("\n      mdi-view-quilt\n    ")])
            : _c("v-icon", [_vm._v("\n      mdi-dots-vertical\n    ")])
        ],
        1
      ),
      _c("v-toolbar-title", {
        staticClass: "hidden-sm-and-down",
        domProps: { textContent: _vm._s(_vm.$route.name) }
      }),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }